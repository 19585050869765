<script lang="ts" setup>
import giftlyFavIcon from '@consumer/assets/images/favicon.ico'
import { useDefaultTheme } from '@consumer/composables/themes'
import ModalsManager from '@consumer/modules/shared/ModalsManager.vue'
import GFlashManager from '@consumer/modules/shared/GFlashManager.vue'
import TheNavigationProgressBar from '@corp/components/TheNavigationProgressBar.vue'
import ToastsManager from '@consumer/modules/layout/ToastsManager.vue'

defineOptions({
  inheritAttrs: false,
})

useDefaultTheme()

onMounted(() => {
  document.querySelector('.woot--bubble-holder')?.classList.add('no-percy')
})
</script>

<template>
  <Head>
    <title>Giftly</title>
    <link rel="icon" :href="giftlyFavIcon">
  </Head>

  <Suspense>
    <main class="flex flex-col h-full w-full" v-bind="$attrs">
      <slot v-bind="$attrs"/>
    </main>
  </Suspense>

  <ToastsManager/>
  <GFlashManager/>
  <ModalsManager/>
  <TheNavigationProgressBar/>
</template>

<style lang="scss">
.fullscreen-mobile {
  @media screen and (max-width: 1023px) {
    .recipient-layout-container {
      @apply overflow-y-hidden flex flex-col fixed inset-0;
    }

    .recipient-layout-footer {
      @apply hidden;
    }
  }
}
</style>
