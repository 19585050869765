<script lang="ts">
export interface FlashMessage {
  message: string
  type?: 'notice' | 'alert'
  duration?: number | 'permanent'
  id?: string
}
</script>

<script setup lang="ts">import { computed as _computed } from 'vue';

import { useDocumentVisibility } from '@vueuse/core'
import { removeMessage } from '@shared/stores/flash'
import { faCircleExclamation, faCircleInfo, faClose } from '@fortawesome/pro-solid-svg-icons'

withDefaults(defineProps<FlashMessage>(), { type: 'notice',duration: 'permanent', })

const isAlert = _computed(() => __props.type === 'alert')

const icon = _computed(() => isAlert.value ? faCircleExclamation : faCircleInfo)
const iconColor = _computed(() => isAlert.value ? 'text-red-base' : 'text-v2-active')

const visibility = (useDocumentVisibility())

if (__props.duration !== 'permanent') {
  watchEffect(() => {
    if (visibility.value === 'visible')
      setTimeout(hideMessage, __props.duration)
  })
}

function hideMessage () {
  removeMessage(__props.id!)
}
</script>

<template>
  <div class="flash-message inline-flex rounded-lg bg-white shadow-v2lg p-4 flex items-center">
    <V2Icon :icon="icon" class="mr-4" :class="iconColor" size="md"/>
    <span class="grow mr-4 whitespace-pre-wrap font-medium">{{ message }}</span>
    <GIconButton class="-mx-2" :icon="faClose" label="Close Message" @click="hideMessage"/>
  </div>
</template>

<style scoped lang="scss">
.flash-message {
  max-width: min(100%, 70ch);
  width: max-content;
}
</style>
