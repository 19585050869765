<!--
  Allows to display messages programatically, ensuring they are displayed on top
  of the current page, and that they are automatically closed, if needed.
-->
<script setup lang="ts">import { toRef as _toRef, computed as _computed } from 'vue';

import { messagesRef } from '@shared/stores/flash'
import { usePage } from '@corp/composables/page'
import type { Errors } from '@corp/serializers'
import { showNotice, showAlert, showError } from '@corp/services/flash'
import GFlashMessage from './GFlashMessage.vue'

export interface Flash {
  alert?: string
  notice?: string
}

withDefaults(defineProps<{
  bottom?: boolean
}>(), {  })

const __$temp_1 = (usePage<{ flash: Flash; errors: Errors }>()),
  props = _toRef(__$temp_1, 'props');

const messages = _computed(() => __props.bottom ? messagesRef.value.reverse() : messagesRef.value)

watch(() => props.value.errors, (errors) => {
  if (errors) showError(errors)
}, { immediate: true })

watch(() => props.value.flash, () => {
  const { alert, notice } = props.value.flash
  if (alert || notice) {
    // Ensure same message is detected as change and displayed each time.
    props.value.flash = {}

    if (alert) showAlert(alert)
    if (notice) showNotice(notice)
  }
}, { immediate: true })
</script>

<template>
  <div class="flash-manager pointer-events-none">
    <TransitionGroup
      class="flex flex-col gap-2 relative items-end"
      :name="bottom ? 'bottom-pop' : 'top-drop'"
      tag="div"
      appear
    >
      <GFlashMessage
        v-for="message in messages"
        :key="message.id"
        class="pointer-events-auto"
        v-bind="message"
      />
    </TransitionGroup>
  </div>
</template>

<style scoped lang="scss">
.flash-manager {
  @media (min-width: 768px) {
    --flash-px: 2rem;
    --flash-pb: 2rem;
  }

  @media (min-width: 1024px) {
    --flash-px: 4rem;
    --flash-pb: 2rem;
  }

  --flash-px: 1rem;
  --flash-pb: 1rem;

  bottom: var(--flash-pb);
  left: var(--flash-px);
  max-width: calc(100vw - 2 * var(--flash-px));
  position: fixed;
  right: var(--flash-px);
  z-index: 100;
}
</style>
