<script setup lang="ts">
import { useScriptTag } from '@vueuse/core'

import bbbLogoAccredited from '@consumer/assets/images/bbbrating.svg'
import { useGift } from '@consumer/composables/gift'
import type { Gift } from '@consumer/types/serializers'
import { isDesktop } from '@consumer/utils/breakpoints'

useScriptTag('https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js')

withDefaults(defineProps<{
  gift?: Gift
}>(), {  })

const {
  isRedeemed,
  isRedemptionRequested,
} = useGift(() => __props.gift)
</script>

<template>
  <div class="bg-white text-navy">
    <GContainer class="py-6 lg:py-12 space-y-6">
      <div class="w-full flex flex-col lg:flex-row-reverse gap-5 lg:gap-20">
        <div
          v-if="isRedeemed || isRedemptionRequested"
          :style="{ width: isDesktop ? '350px' : '100%'}"
          class="!font-sans border-1 lg:border-0 border-solid border-grey-300"
        >
          <div class="klaviyo-form-VUVtG3"/>
        </div>
        <div class="flex-1">
          <h1 class="text-xl mb-4">
            For over 10 years, Giftly has delivered happiness to millions of customers.
          </h1>
          <p>
            With merchants coast to coast, you can send a gift for use at almost any business, from
            local favorites to nationwide chains. Treat friends and family to places they'll enjoy.
          </p>
          <div class="flex gap-8 justify-left items-center">
            <img :src="bbbLogoAccredited" height="52" width="137">
            <div
              class="trustpilot-widget"
              data-locale="en-US"
              data-template-id="53aa8807dec7e10d38f59f32"
              data-businessunit-id="5aa773d465a75300015d8d3b"
              data-style-height="56px"
              data-style-width="140px"
              data-theme="light"
            >
              <a
                href="https://www.trustpilot.com/review/giftly.com"
                target="_blank"
                rel="noopener"
              >
                Trustpilot
              </a>
            </div>
          </div>
        </div>

      </div>
    </GContainer>
  </div>
</template>
